import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";
import ImageInfoSmall from "../image-info-small";

const AdvertiserInfo = () => {
  const value = FacebookState.useGlobalState((gs) => gs.pageName);

  return (
    <Chakra.Box width="100%">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Advertiser Info
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
      >
        Specify the Facebook page that your ads will be associated with.
      </Chakra.Text>
      <Chakra.HStack width="100%">
        <ImageInfoSmall onChange={FacebookState.setPageLogo} />
        <Chakra.InputGroup width="100%">
          <Chakra.Input
            placeholder="Page Name"
            width="100%"
            borderRadius="2px"
            height="40px"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#bababa"
            _placeholder={{
              fontSize: "14px",
              color: "#000",
            }}
            value={value}
            onChange={(e) => FacebookState.setPageName(e.target.value, 20)}
          />
          <Chakra.InputRightElement
            children={
              <Chakra.Text fontSize="13px" color="#c8232a">
                {Math.max(20 - value.length, 0)}
              </Chakra.Text>
            }
          />
        </Chakra.InputGroup>
      </Chakra.HStack>
    </Chakra.Box>
  );
};

export default AdvertiserInfo;
