import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState, {
  PossibleReactions,
  Reaction,
} from "./facebook-state-machine";

const LinkEmoji = () => {
  const reactionTypeValues = FacebookState.useGlobalState(
    (gs) => gs.reactionTypes
  );

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Reactions
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        maxWidth="480px"
      >
        Add reactions to your Facebook ad.
      </Chakra.Text>
      <Chakra.Grid templateColumns="repeat(3, 1fr)" width="100%" gap={6}>
        {PossibleReactions.map((reaction: Reaction) => {
          let isChecked = reactionTypeValues.includes(reaction.name);
          return (
            <Chakra.Flex
              pt="40px"
              onClick={() => FacebookState.changeReactionTypes(reaction.name)}
              cursor="pointer"
              alignItems="center"
              justifyContent="center"
            >
              <Chakra.Circle
                w={4}
                h={4}
                transition="background-color 0.2s"
                border={isChecked ? "2px solid #3182ce" : "2px solid #BABABA"}
                backgroundColor={isChecked ? "blue.500" : "white"}
                _before={{
                  borderRadius: "50%",
                  content: '""',
                  display: "inline-block",
                  height: "50%",
                  width: "50%",
                  backgroundColor: "#fff",
                  position: "relative",
                  border: "1px solid transparent",
                }}
              />
              <Chakra.HStack pl="16px">
                <reaction.image />
                <Chakra.Text
                  fontSize="14px"
                  lineHeight="20px"
                  letterSpacing="0.11px"
                >
                  {reaction.name}
                </Chakra.Text>
              </Chakra.HStack>
            </Chakra.Flex>
          );
        })}
      </Chakra.Grid>
    </Chakra.Box>
  );
};

export default LinkEmoji;
