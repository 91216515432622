import React from "react";
import * as Chakra from "@chakra-ui/react";
import { Link, PageProps } from "gatsby";
import { Seo } from "../components/seo";
import FacebookAdOptions from "../components/facebook/facebook-ad-options";
import { LivePreviewWindow } from "../components/live-preview-window";
import { FacebookAdMockup } from "../components/facebook/facebook-ad-mockup";
import NextPageButton from "../components/next-page-button";

const Index = ({ location }: PageProps): React.ReactElement => (
  <Chakra.Box width="100%">
    <Seo title="Facebook" location={location} />
    <Chakra.Flex
      width="100%"
      flexDir={{ base: "column", lg: "row" }}
      align="start"
      justify="center"
      position="sticky"
      top="0"
      bottom="0"
      left="0"
      right="0"
      overflowY="hidden"
      height="100%"
    >
      <FacebookAdOptions />
      <Chakra.Box
        width="100%"
        m="auto"
        pb={{ base: "50px", lg: "0" }}
        px={{ base: "20px", lg: "0" }}
      >
        <LivePreviewWindow maxWidth="439px" m="auto">
          <FacebookAdMockup />
        </LivePreviewWindow>
      </Chakra.Box>
    </Chakra.Flex>
    <NextPageButton as={Link} to="/email-newsletter">
      Next Step
    </NextPageButton>
  </Chakra.Box>
);

export default Index;
