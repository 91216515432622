import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";

const LinkInfo = () => {
  const linkHeadlineValue = FacebookState.useGlobalState(
    (gs) => gs.linkHeadline
  );
  const linkDescriptionValue = FacebookState.useGlobalState(
    (gs) => gs.linkDescription
  );
  const linkCaptionValue = FacebookState.useGlobalState((gs) => gs.linkCaption);

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Link
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        Write a description headline, description and caption for your URL
      </Chakra.Text>
      <Chakra.VStack width="100%" justify="start" align="start">
        <Chakra.InputGroup width="100%">
          <Chakra.Input
            placeholder="Link Headline"
            width="100%"
            borderRadius="2px"
            height="40px"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#bababa"
            _placeholder={{
              fontSize: "14px",
              color: "#000",
            }}
            value={linkHeadlineValue}
            onChange={(e) => FacebookState.setLinkHeadline(e.target.value, 40)}
          />
          <Chakra.InputRightElement
            children={
              <Chakra.Text fontSize="13px" color="#c8232a">
                {Math.max(40 - linkHeadlineValue.length, 0)}
              </Chakra.Text>
            }
          />
        </Chakra.InputGroup>
        <Chakra.InputGroup width="100%">
          <Chakra.Input
            placeholder="Link Description"
            width="100%"
            borderRadius="2px"
            height="40px"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#bababa"
            _placeholder={{
              fontSize: "14px",
              color: "#000",
            }}
            value={linkDescriptionValue}
            onChange={(e) => FacebookState.setLinkDescription(e.target.value, 40)}
          />
          <Chakra.InputRightElement
            children={
              <Chakra.Text fontSize="13px" color="#c8232a">
                {40 - linkDescriptionValue.length}
              </Chakra.Text>
            }
          />
        </Chakra.InputGroup>
        <Chakra.InputGroup width="100%">
          <Chakra.Input
            placeholder="Link Caption"
            width="100%"
            borderRadius="2px"
            height="40px"
            borderWidth="1px"
            borderStyle="solid"
            borderColor="#bababa"
            _placeholder={{
              fontSize: "14px",
              color: "#000",
            }}
            value={linkCaptionValue}
            onChange={(e) => FacebookState.setLinkCaption(e.target.value, 40)}
          />
          <Chakra.InputRightElement
            children={
              <Chakra.Text fontSize="13px" color="#c8232a">
                {40 - linkCaptionValue.length}
              </Chakra.Text>
            }
          />
        </Chakra.InputGroup>
      </Chakra.VStack>
    </Chakra.Box>
  );
};

export default LinkInfo;
