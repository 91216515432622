import React from "react";
import * as Chakra from "@chakra-ui/react";
import Camera from "../images/camera.svg";
import { FileDrop } from "react-file-drop";

interface ImageInfoSmallProps {
  onChange: any;
}

const ImageInfoSmall = ({
  onChange,
}: ImageInfoSmallProps): React.ReactElement => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [isInvalidType, setIsInvalidType] = React.useState<boolean>(false);

  const handleClickToUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (event) => {
    setIsDragging(false);
    const file = event.target.files[0];
    doFileCheckAndState(file);
  };

  const handleDropFileUpload = (event) => {
    setIsDragging(false);
    doFileCheckAndState(event[0]);
  };

  const doFileCheckAndState = (file) => {
    if (file) {
      setIsProcessing(true);
      console.log(file);
      console.log(file.type);
      const splitType = file.type.split("/");
      if (splitType[0] === "image" && !splitType[1].includes("svg")) {
        if (onChange) {
          onChange(file);
        }
        setIsProcessing(false);
      } else {
        setIsProcessing(false);
        setIsInvalidType(true);
        setTimeout(() => {
          setIsInvalidType(false);
        }, 3000);
      }
    }
  };

  return (
    <Chakra.Box
      onClick={handleClickToUpload}
      cursor="pointer"
      position="relative"
    >
      <Chakra.Input
        border="none"
        type="file"
        ref={hiddenFileInput}
        _focus={{ border: "none" }}
        left="0"
        bottom="0"
        position="absolute"
        height="0"
        width="0"
        opacity="0"
        onChange={handleFileUpload}
      />
      <Chakra.Center
        width="68px"
        height="40px"
        backgroundColor={isInvalidType ? "#c8232a" : "#243475"}
        color="#fff"
        cursor="pointer"
        as={FileDrop}
        onDragOver={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={handleDropFileUpload}
        boxShadow={isDragging ? "0 0 8px #e2e0df inset" : undefined}
        _hover={{
          boxShadow: "0 0 8px #e2e0df inset",
        }}
        transition="0.3s"
      >
        {isProcessing ? <Chakra.Spinner /> : <Camera />}
      </Chakra.Center>
    </Chakra.Box>
  );
};

export default ImageInfoSmall;
