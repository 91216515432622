import React from "react";
import * as Chakra from "@chakra-ui/react";

export const LivePreviewWindow = ({
  children,
  ...props
}: Chakra.BoxProps): React.ReactElement => {
  return (
    <Chakra.Box pt={{ lg: "70px" }} width="100%" {...props}>
      <Chakra.Box
        mb="20px"
        border="1px solid #333333"
        width="100%"
        borderRadius="6px"
        overflow="hidden"
      >
        {children}
      </Chakra.Box>
    </Chakra.Box>
  );
};
