import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";

const MessageInfo = () => {
  const value = FacebookState.useGlobalState((gs) => gs.message);

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Message
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        Grab interest with more info about what you're advertising. Keep it
        short. We recommend 90 characters or less.
      </Chakra.Text>
      <Chakra.InputGroup width="100%">
        <Chakra.Textarea
          placeholder="Message"
          width="100%"
          borderRadius="2px"
          height="95px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="#bababa"
          _placeholder={{ fontSize: "14px", color: "#000" }}
          value={value}
          onChange={(e) => FacebookState.setMessage(e.target.value, 125)}
        />
        <Chakra.InputRightElement
          children={
            <Chakra.Text fontSize="13px" color="#c8232a">
              {Math.max(125 - value.length, 0)}
            </Chakra.Text>
          }
        />
      </Chakra.InputGroup>
    </Chakra.Box>
  );
};

export default MessageInfo;
