import React from "react";
import * as Chakra from "@chakra-ui/react";
import Camera from "../images/camera.svg";
import { FileDrop } from "react-file-drop";

interface ImageInfoProps {
  title: string;
  text: string;
  onChange: any;
}

const ImageInfo = ({
  title,
  text,
  onChange,
}: ImageInfoProps): React.ReactElement => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
  const [isInvalidType, setIsInvalidType] = React.useState<boolean>(false);

  const handleClickToUpload = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (event) => {
    setIsDragging(false);
    const file = event.target.files[0];
    doFileCheckAndState(file);
  };

  const handleDropFileUpload = (event) => {
    setIsDragging(false);
    doFileCheckAndState(event[0]);
  };

  const doFileCheckAndState = (file) => {
    if (file) {
      setIsProcessing(true);
      console.log(file);
      console.log(file.type);
      const splitType = file.type.split("/");
      if (splitType[0] === "image" && !splitType[1].includes("svg")) {
        if (onChange) {
          onChange(file);
        }
        setIsProcessing(false);
      } else {
        setIsProcessing(false);
        setIsInvalidType(true);
        setTimeout(() => {
          setIsInvalidType(false);
        }, 3000);
      }
    }
  };

  return (
    <Chakra.Box width="100%" pt="44px" position="relative">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        {title}
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        {text}
      </Chakra.Text>
      <Chakra.Box onClick={handleClickToUpload} cursor="pointer">
        <Chakra.Input
          border="none"
          type="file"
          ref={hiddenFileInput}
          _focus={{ border: "none" }}
          height="0"
          width="0"
          opacity="0"
          left="0"
          bottom="0"
          position="absolute"
          onChange={handleFileUpload}
        />
        <Chakra.Center
          width="100%"
          borderStyle="dashed"
          height="95px"
          borderWidth="1px"
          borderColor="#bababa"
          borderRadius="2px"
          cursor="pointer"
          as={FileDrop}
          onDragOver={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDropFileUpload}
          boxShadow={isDragging ? "0 0 8px #e2e0df inset" : undefined}
          _hover={{
            boxShadow: "0 0 8px #e2e0df inset",
          }}
          transition="0.3s"
        >
          <Chakra.VStack justify="center" align="center" width="100%">
            {isProcessing ? (
              <Chakra.Spinner />
            ) : (
              <>
                <Chakra.Box cursor="pointer">
                  <Camera />
                </Chakra.Box>
                <Chakra.Text
                  fontSize="12px"
                  lineHeight="20px"
                  letterSpacing="0.1px"
                  color={isInvalidType ? "#c8232a" : "#000"}
                >
                  {isDragging && "Drop your image here"}
                  {isInvalidType &&
                    "Invalid file type. All image file types accepted (except SVG)"}
                  {!isDragging &&
                    !isInvalidType &&
                    "Browse or drag and drop to upload an image"}
                </Chakra.Text>
              </>
            )}
          </Chakra.VStack>
        </Chakra.Center>
      </Chakra.Box>
    </Chakra.Box>
  );
};

export default ImageInfo;
