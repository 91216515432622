import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";

const Reaction = () => {
  const value = FacebookState.useGlobalState((gs) => gs.reactionCount);

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Input
        placeholder="Reaction Count"
        width="100%"
        borderRadius="2px"
        height="40px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="#bababa"
        _placeholder={{ fontSize: "14px", color: "#000" }}
        value={value}
        onChange={(e) => FacebookState.setReactionCount(e.target.value)}
      />
    </Chakra.Box>
  );
};

export default Reaction;
