import React from "react";
import * as Chakra from "@chakra-ui/react";
import AdvertiserInfo from "./advertiser-info";
import MessageInfo from "./message-info";
import ImageInfo from "../image-info";
import CallToAction from "./call-to-action-info";
import LinkInfo from "./link-info";
import LinkEmoji from "./link-emoji";
import Reaction from "./reaction";
import PageSharesInfo from "./page-shares-info";
import PageCommentsInfo from "./page-comments-info";
import PageScrollButton from "../page-scroll-button";
import { HeadingMedium } from "../heading-medium";
import { BodyText } from "../body-text";
import { FormScrollArea } from "../form-scroll-area";
import FacebookState from "./facebook-state-machine";

const FacebookAdOptions = () => {
  const scrollAreaRef = React.useRef<HTMLDivElement>();

  return (
    <>
      <Chakra.Box width="100%" height="100%">
        <Chakra.VStack
          justify="start"
          align="start"
          width="100%"
          height="100%"
          py={{ base: "20px", lg: "80px" }}
          pl={{ base: "20px", lg: "82px" }}
          pr={{ base: "20px", lg: "0" }}
        >
          <FormScrollArea ref={scrollAreaRef}>
            <HeadingMedium>Facebook</HeadingMedium>
            <BodyText>
              Facebook Ads are an effective tool to generate leads so that you
              can quickly and cheaply test a new offer. With incredible audience
              targeting options, it’s easy to get your new proposition in front
              of the right people, driving traffic to your website or landing
              page.
            </BodyText>
            <AdvertiserInfo />
            <MessageInfo />
            <ImageInfo
              title="Image"
              text="Compelling images will encourage your target audience to engage. Recommended image size: 1,200 x 628 pixels."
              onChange={FacebookState.setPageImage}
            />
            <CallToAction />
            <LinkInfo />
            <LinkEmoji />
            <Reaction />
            <PageSharesInfo />
            <PageCommentsInfo />
          </FormScrollArea>
          <PageScrollButton scrollArea={scrollAreaRef} />
        </Chakra.VStack>
      </Chakra.Box>
    </>
  );
};

export default FacebookAdOptions;
