import React from "react";
import * as Chakra from "@chakra-ui/react";
import UpArrow from "../images/up-arrow.svg";
import DownArrow from "../images/down-arrow.svg";

interface PageScrollButtonProps {
  scrollArea: React.RefObject<HTMLElement>;
}

const PageScrollButton = ({
  scrollArea,
}: PageScrollButtonProps): React.ReactElement => {
  const [shouldScroll, setShouldScroll] = React.useState<boolean | string>(
    false
  );

  React.useEffect(() => {
    let intervalId;

    if (shouldScroll && scrollArea && scrollArea.current) {
      intervalId = setInterval(() => {
        scrollArea.current.scrollBy(0, shouldScroll === "up" ? -5 : 5);
      }, 30);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [shouldScroll]);

  return (
    <Chakra.Box
      position="absolute"
      bottom="0"
      left="0"
      width="100%"
      display={{ base: "none", lg: "unset" }}
    >
      <Chakra.Box width="100%">
        <Chakra.Center
          _hover={{ backgroundColor: "#E6B822" }}
          transition="0.2s"
          cursor="pointer"
          bg="#EEEDEC"
          width="35px"
          height="35px"
          onMouseOver={() => setShouldScroll("up")}
          onMouseOut={() => setShouldScroll(false)}
        >
          <UpArrow />
        </Chakra.Center>
        <Chakra.Center
          _hover={{ backgroundColor: "#E6B822" }}
          transition="0.2s"
          cursor="pointer"
          bg="#EEEDEC"
          width="35px"
          height="35px"
          mt="0px"
          onMouseOver={() => setShouldScroll("down")}
          onMouseOut={() => setShouldScroll(false)}
        >
          <DownArrow />
        </Chakra.Center>
      </Chakra.Box>
    </Chakra.Box>
  );
};

export default PageScrollButton;
