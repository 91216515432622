import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";

const CallToAction = () => {
  const value = FacebookState.useGlobalState((gs) => gs.callToAction);

  const possibleCTAs = [
    "Apply Now",
    "Book Now",
    "Buy Tickets",
    "Call Now",
    "Contact Us",
    "Donate Now",
    "Download",
    "Get Directions",
    "Get Offer",
    "Get Quote",
    "Get Showtimes",
    "Install Now",
    "Learn More",
    "Like Page",
    "Listen Now",
    "Open Link",
    "Order Now",
    "Play Game",
    "Request Time",
    "Save",
    "See Menu",
    "Send Message",
    "Send WhatsApp Message",
    "Shop Now",
    "Sign Up",
    "Subscribe",
    "Use App",
    "View Event",
    "Watch More",
  ];

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Call to Action
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        Encourage people to click with an explicit call to action button.
      </Chakra.Text>
      <Chakra.Select
        placeholder="Please Select"
        borderRadius="2px"
        height="40px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="#bababa"
        value={value}
        _placeholder={{ fontSize: "14px", color: "#000", fontWeight: "bold" }}
        onChange={(e) => FacebookState.setCallToAction(e.target.value)}
      >
        {possibleCTAs.map((cta) => (
          <option key={cta} value={cta}>
            {cta}
          </option>
        ))}
      </Chakra.Select>
    </Chakra.Box>
  );
};

export default CallToAction;
