import React from "react";
import * as Chakra from "@chakra-ui/react";
import FacebookState from "./facebook-state-machine";

const PageSharesInfo = () => {
  const value = FacebookState.useGlobalState((gs) => gs.pageShares);

  return (
    <Chakra.Box width="100%" pt="44px">
      <Chakra.Text
        fontSize="14px"
        fontWeight="500"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
      >
        Page Shares
      </Chakra.Text>
      <Chakra.Text
        fontSize="14px"
        fontWeight="300"
        lineHeight="20px"
        letterSpacing="0.11px"
        color="#000"
        pb="35px"
        maxWidth="480px"
      >
        Set the number of page shares for your page. Numeric input only.
      </Chakra.Text>
      <Chakra.Input
        placeholder="Please Enter"
        width="100%"
        borderRadius="2px"
        height="40px"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="#bababa"
        _placeholder={{ fontSize: "14px", color: "#000" }}
        value={value}
        onChange={(e) => FacebookState.setPageShares(e.target.value)}
      />
    </Chakra.Box>
  );
};

export default PageSharesInfo;
